<template>
  <div v-loading="loading" class="ExamDistribute">
    <div class="operate">
      <div class="filter">
        <div class="filter-item">
          学段：
          <el-select
            v-model="search.level"
            style="width: 100px"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="filter-item">
          入学年份：
          <el-select
            v-model="search.year"
            style="width: 100px"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-tooltip class="item" effect="dark" content="" placement="bottom">
        <div slot="content" class="tooltip-conent">
          <div>重置当前考务分工</div>
          <div>考生管理，统计参数设置：为空</div>
          <div>临时成绩榜：年级组长、备课组长</div>
          <div>讲评报告：备课组长、班主任、任课教师</div>
          <div>进度监控：年级组长、本学科所有任课教师</div>
          <div>阅卷质量：年级组长、本学科备课组长</div>
        </div>
        <el-button type="primary" @click="reset">重置考务分工</el-button>
      </el-tooltip>
    </div>
    <div class="title">考试权限</div>
    <div class="tableList">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          prop="date"
          label="功能名称"
          align="center"
          width="180"
        >
          <template slot-scope="{ row }">
            <span v-if="row.type === 1">考生管理</span>
            <span v-if="row.type === 4">统计参数设置</span>
            <span v-if="row.type === -1">讲评报告</span>
            <span v-if="row.type === 5">临时成绩榜</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="具有该功能的教师" align="center">
          <template slot-scope="{ row }">
            {{ row.teacherNameList.toString() }}
          </template>
        </el-table-column>
        <el-table-column label="设置教师" width="180" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="setting(row)">设置</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="title">科目分工</div>
    <div v-if="subjectTable.length" class="tableList">
      <el-table :data="subjectTable" border style="width: 100%">
        <el-table-column
          prop="subjectName"
          label="科目"
          align="center"
          width="180"
        >
          <template slot-scope="{ row }">
            <div class="subjectCell">
              <div class="subjectName">{{ getSubjectName(row) }}</div>
              <div class="subjectSetting">
                <el-button icon="el-icon-setting" @click="subjectSetting(row)"
                  >批量设置</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="subjectSheet" label="答题卡设置" align="center">
          <template slot="header" slot-scope="scope">
            <div class="col">
              <el-button icon="el-icon-setting" @click="colClick(1)">
                批量设置
              </el-button>
              <div>{{ scope.column.label }}</div>
            </div>
          </template>
          <template slot-scope="{ row }">
            <div class="clickCell" @click="setting(row, 1)">
              {{ row ? row.subjectSheet?.teacherNameList?.toString() : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="subjectAnswer" label="答案设置" align="center">
          <template slot="header" slot-scope="scope">
            <div class="col">
              <el-button icon="el-icon-setting" @click="colClick(2)">
                批量设置
              </el-button>
              <div>{{ scope.column.label }}</div>
            </div>
          </template>
          <template slot-scope="{ row }">
            <div class="clickCell" @click="setting(row, 2)">
              {{ row ? row.subjectAnswer?.teacherNameList?.toString() : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="subjectTypework" label="阅卷分工" align="center">
          <template slot="header" slot-scope="scope">
            <div class="col">
              <el-button icon="el-icon-setting" @click="colClick(3)">
                批量设置
              </el-button>
              <div>{{ scope.column.label }}</div>
            </div>
          </template>
          <template slot-scope="{ row }">
            <div class="clickCell" @click="setting(row, 3)">
              {{ row.subjectTypework?.teacherNameList?.toString() }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="subjectTypeSwitch"
          label="阅卷开关"
          align="center"
        >
          <template slot="header" slot-scope="scope">
            <div class="col">
              <el-button icon="el-icon-setting" @click="colClick(6)">
                批量设置
              </el-button>
              <div>{{ scope.column.label }}</div>
            </div>
          </template>
          <template slot-scope="{ row }">
            <div class="clickCell" @click="setting(row, 6)">
              {{ row.subjectTypeSwitch?.teacherNameList?.toString() }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="subjectTypeschedule"
          label="进度监控"
          align="center"
        >
          <template slot="header" slot-scope="scope">
            <div class="col">
              <el-button icon="el-icon-setting" @click="colClick(8)">
                批量设置
              </el-button>
              <div>{{ scope.column.label }}</div>
            </div>
          </template>
          <template slot-scope="{ row }">
            <div class="clickCell" @click="setting(row, 8)">
              {{ row.subjectTypeschedule?.teacherNameList?.toString() }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="subjectTypeQuality"
          label="阅卷质量"
          align="center"
        >
          <template slot="header" slot-scope="scope">
            <div class="col">
              <el-button icon="el-icon-setting" @click="colClick(9)">
                批量设置
              </el-button>
              <div>{{ scope.column.label }}</div>
            </div>
          </template>
          <template slot-scope="{ row }">
            <div class="clickCell" @click="setting(row, 9)">
              {{ row.subjectTypeQuality?.teacherNameList?.toString() }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      title="批量设置该权限教师"
      :visible.sync="dialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <div class="dialog-filter">
        <div class="dialog-filter-item">
          学段：
          <el-select
            v-model="dialogSearch.level"
            style="width: 120px"
            clearable
            placeholder="请选择"
            @change="getSchoolTeacherList"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dialog-filter-item">
          年份：
          <el-select
            v-model="dialogSearch.year"
            style="width: 120px"
            clearable
            placeholder="请选择"
            @change="getSchoolTeacherList"
          >
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dialog-filter-item">
          科目：
          <el-select
            v-model="dialogSearch.subjectId"
            style="width: 120px"
            clearable
            placeholder="请选择"
            @change="getSchoolTeacherList"
          >
            <el-option
              v-for="item in subjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dialog-filter-item">
          角色权限：
          <el-select
            v-model="dialogSearch.type"
            style="width: 120px"
            clearable
            placeholder="请选择"
            @change="getSchoolTeacherList"
          >
            <el-option
              v-for="item in teacherRole"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dialog-filter-item">
          教师姓名：
          <el-input
            v-model="dialogSearch.name"
            placeholder="请输入教师姓名"
            style="width: 150px"
            @input="getSchoolTeacherList"
          ></el-input>
        </div>
      </div>
      <div class="teacher-box">
        <div class="box">
          <div class="teacher-header">
            <el-button type="text" @click="allCheck">全选</el-button>
            <el-button type="text" @click="reverse">反选</el-button>
          </div>
          <div v-loading="conetntLoaindg" class="teacher-content">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="item in teacherList"
                :key="item.id"
                :label="item.teacherId"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
        <div class="box">
          <div class="teacher-header">
            <el-button type="text" @click="clearAll">清空</el-button>
          </div>
          <div class="teacher-content">
            <div v-for="(item, index) in checkList" :key="index" class="item">
              <div class="name">{{ getTeacherName(item, index) }}</div>
              <div class="close" @click="delItem(index)">
                <i class="el-icon-close"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { levelOptions, teacherRole } from "@/core/util/constdata";
import { getYear, getSubjectList } from "@/core/util/util";
import {
  getExamDivisonParams,
  setExamDivisonParams,
  resetExamwork,
} from "@/core/api/newOther/index";
import { schoolteacherlistv2 } from "@/core/api/school/schoolteacher";
export default {
  data() {
    const schoolId = JSON.parse(localStorage.getItem("jzjx-school_id")).content;
    const levelList = levelOptions();
    let initLevel = null;
    if (levelList.length) {
      initLevel = levelList[0].value;
    }
    const nowYear = new Date().getFullYear();
    return {
      search: {
        level: initLevel,
        year: nowYear,
      },
      levelOptions: [...levelList],
      yearList: getYear(),
      loading: false,
      schoolId: schoolId,
      tableData: [],
      dialogVisible: false,
      dialogSearch: {},
      subjectOptions: getSubjectList(),
      teacherRole: teacherRole,
      teacherList: [],
      checkList: [],
      form: {},
      submitLoading: false,
      conetntLoaindg: false,
      allTeacherList: [],
      subjectTable: [],
      allTableData: [],
      subjectTypeEnmu: {
        1: "subjectSheet",
        2: "subjectAnswer",
        3: "subjectTypework",
        8: "subjectTypeschedule",
        6: "subjectTypeSwitch",
        9: "subjectTypeQuality",
      },
      // subject_type:答题卡:1，标准答案:2，阅卷分工:3，异常处理:4，
      //学生答题卡:5，阅卷开关:6，阅卷报警:7，
      //监控进度:8，阅卷质量:9，单科统计:10

      // type:区分考试分工类型(
      // 班级讲评报告:-1
      // 考生管理:1，
      // 监控扫描:2，
      // 阅卷重评:3，
      // 统计参数设置:4，
      // 临时成绩榜:5，
      // 考试开关:6，
      // 拆分科目:7，
      // 合并科目:8,
      // 科目分工：9，阅卷分配：10)
    };
  },
  watch: {
    search: {
      deep: true,
      handler() {
        if (this.search.year && this.search.level) {
          this.getData();
        }
      },
    },
  },
  mounted() {
    this.getData();
    this.getAllTeacherList();
  },
  methods: {
    reset() {
      this.$confirm(
        "此操作将重置当前考务分工数据,无法恢复, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            const res = await resetExamwork({ ...this.search });
            if (res.data.code === 0) {
              this.$message({
                type: "success",
                message: "重置成功!",
              });
              this.getData();
            }
          } catch {
            this.$message({
              type: "info",
              message: "重置失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async colClick(subjectType) {
      let colData = this.allTableData.filter(
        (item) => item.subjectType == subjectType
      );
      let flatMapArr = colData.flatMap((item) => item.teacherIdList);
      this.checkList = [...new Set(flatMapArr)];
      let ids = colData.map((item) => item.id);
      if (colData.length) {
        this.dialogSearch = {
          year: colData[0].year,
          level: colData[0].level.toString(),
          subjectId: colData[0].subjectId || null,
          type: subjectType == 9 ? 3 : null,
        };
        this.form = {
          ...this.allTableData[0],
          ids: ids.toString(),
        };
        this.sleep(50);
      }
      this.getSchoolTeacherList();
      this.dialogVisible = true;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async subjectSetting(row) {
      let subjectTypeField = [];
      let ids = [];
      let teahcerList = [];
      for (let key in this.subjectTypeEnmu) {
        subjectTypeField.push(this.subjectTypeEnmu[key]);
        await this.sleep(500);
      }
      for (let key in row) {
        if (subjectTypeField.includes(key) && row[key]) {
          ids.push(row[key].id);
          teahcerList = [...teahcerList, ...row[key].teacherIdList];
        }
        await this.sleep(500);
      }
      this.form = {
        ...row,
        ids: ids.join(","),
      };
      this.dialogSearch = {
        level: row.level.toString(),
        year: row.year,
        subjectId: row.subjectId || null,
      };
      this.checkList = [...new Set(teahcerList)];
      this.getSchoolTeacherList();
      this.dialogVisible = true;
    },
    getSubjectName(row) {
      if (row) {
        const data = this.subjectOptions.find(
          (item) => item.value === row.subjectId
        );
        return data ? data.label : "";
      }
    },
    delItem(index) {
      this.checkList.splice(index, 1);
    },
    submit() {
      this.submitLoading = true;
      let params = {
        teacherIds: this.checkList.toString(),
        ids: this.form.ids,
        schoolId: this.form.schoolId,
        type: this.form.type,
        subjectType: this.form.subjectType,
        subjectId: this.form.subjectId,
        year: this.form.year,
        level: this.form.level,
      };
      setExamDivisonParams({ ...params })
        .then(() => {
          this.submitLoading = false;
          this.getData();
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: "提交成功",
            type: "success",
          });
        })
        .catch(() => {
          this.submitLoading = false;
          this.dialogVisible = false;
        });
    },
    getTeacherName(id, index) {
      if (this.allTeacherList.length) {
        const data = this.allTeacherList.find((item) => item.teacherId === id);
        if (!data) {
          this.checkList.splice(index, 1);
        }
        return data ? data.name : null;
      }
    },
    async getData() {
      this.loading = true;
      this.tableData = [];
      try {
        const res = await getExamDivisonParams({
          ...this.search,
          schoolId: this.schoolId,
        });
        this.loading = false;
        let result = [-1, 1, 4, 5];
        this.allTableData = res.data.data;
        this.tableData = res.data.data.filter((item) =>
          result.includes(item.type)
        );
        let arr = res.data.data.filter((item) => item.type === 9);
        const subjectList = [
          ...new Set(
            res.data.data
              .map((item) => item.subjectId)
              .filter((item) => item > 0)
          ),
        ];
        let data = subjectList.map((item) => {
          let filterArr = arr.filter((it) => it.subjectId == item);

          let subjectSheet = filterArr.find((item) => item.subjectType == 1);
          let subjectAnswer = filterArr.find((item) => item.subjectType == 2);
          let subjectTypework = filterArr.find((item) => item.subjectType == 3);
          let subjectTypeschedule = filterArr.find(
            (item) => item.subjectType == 8
          );
          let subjectTypeSwitch = filterArr.find(
            (item) => item.subjectType == 6
          );
          let subjectTypeQuality = filterArr.find(
            (item) => item.subjectType == 9
          );
          if (filterArr.length) {
            return {
              type: filterArr[0].type,
              level: filterArr[0].level,
              year: filterArr[0].year,
              subjectId: item,
              subjectSheet: subjectSheet,
              subjectAnswer: subjectAnswer,
              subjectTypework: subjectTypework,
              subjectTypeschedule: subjectTypeschedule,
              subjectTypeSwitch: subjectTypeSwitch,
              subjectTypeQuality: subjectTypeQuality,
              schoolId: filterArr[0].schoolId,
            };
          }
          return {};
        });
        this.subjectTable = [...data.filter((item) => item.subjectId > 0)];
      } catch {
        this.loading = false;
      }
    },
    removeRepeat(arr, key) {
      // 去重
      let data = arr.filter(
        (ele, index, a) => a.map((e) => e[key]).indexOf(ele[key]) === index
      );
      return data;
    },
    allCheck() {
      let arr = this.teacherList.map((item) => item.teacherId);
      this.checkList = [
        ...this.checkList,
        ...arr.filter((item) => !this.checkList.includes(item)),
      ];
    },

    reverse() {
      let arr = this.teacherList.map((item) => item.teacherId);
      let checkArr = this.checkList.filter((item) => arr.includes(item)); // 求出交集
      let values = this.checkList.filter((item) => !checkArr.includes(item));
      this.checkList = [
        ...values,
        ...arr.filter((item) => !this.checkList.includes(item)),
      ];
    },
    clearAll() {
      this.checkList = [];
    },
    setting(row, subjectType) {
      this.form = {
        ...row,
        ids: row.id,
      };
      if (subjectType) {
        this.form.subjectType = subjectType;

        this.form.ids = row[this.subjectTypeEnmu[subjectType]]
          ? row[this.subjectTypeEnmu[subjectType]].id
          : "";
      }
      this.dialogSearch = {
        level: row.level?.toString(),
        year: row.year,
        subjectId: row.subjectId || null,
      };
      this.checkList = row.teacherIdList || [];
      this.getSchoolTeacherList();
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    getAllTeacherList() {
      schoolteacherlistv2({ examPaperId: 0 }).then((res) => {
        this.allTeacherList = res.data.data;
      });
    },
    getSchoolTeacherList() {
      this.conetntLoaindg = true;
      schoolteacherlistv2({ examPaperId: 0, ...this.dialogSearch }).then(
        (res) => {
          this.teacherList = res.data.data;
          this.conetntLoaindg = false;
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamDistribute {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .filter {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #101011;
      .filter-item {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }

  .title {
    padding-left: 8px;
    color: #101011;
    font-size: 14px;
    margin-bottom: 24px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      background-color: #2474ed;
      transform: translateY(-50%);
      width: 2px;
      height: 10px;
      border-radius: 1px;
    }
  }
  .tableList {
    margin-bottom: 24px;
  }
}
.dialog-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .dialog-filter-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 18px;
  }
}
.teacher-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .box {
    flex: 1;
    margin-right: 18px;
    border: 1px solid #dee1e7;

    .teacher-header {
      height: 37px;
      background: #f5f7fa;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 9px 24px;
    }
    .teacher-content {
      box-sizing: border-box;
      padding: 18px;
      height: 338px;
      overflow-y: auto;
      & ::-webkit-scrollbar {
        width: 3px;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding: 10px;
        color: #2474ed;
        .close {
          cursor: pointer;
          i {
            color: #2474ed;
          }
        }
      }
    }
  }
  & :last-child {
    margin-right: 0;
  }
}
.el-checkbox {
  display: block;
  box-sizing: border-box;
  padding: 10px;
  margin: 0;
}
.clickCell {
  width: 100%;
  height: 100%;
  border: 1px solid #dee1e3;
  cursor: pointer;
  border-radius: 4px;
  text-align: left;
  box-sizing: border-box;
  padding: 0 10px;
  min-height: 40px;
}
.subjectCell {
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  .subjectName {
    display: block;
  }
  .subjectSetting {
    display: none;
  }
  &:hover {
    .subjectName {
      display: none;
    }
    .subjectSetting {
      display: block;
    }
  }
}
.col {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    margin-right: 10px;
  }
}
.tooltip-conent {
  line-height: 1.5;
}
</style>
